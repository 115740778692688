import { Entity } from '.';
import Activity from './activity';
import { IAttachmentIssue } from './attachment';
import ICampaign from './campaign';
import { IMetadata } from './metadata';
import { IUser } from './user';

export const isIssue = (input: unknown): input is IIssue =>
    !!input &&
    typeof input === 'object' &&
    ['attachments', 'campaign', 'testSteps', 'configurations', 'id', 'severity', 'status', 'description', 'type'].every(
        (property) => property in input
    );

interface IIssue extends Entity {
    assignee?: IUser;
    attachments: IAttachmentIssue[];
    campaign: ICampaign;
    comment?: string;
    readonly createdAt: Date | string;
    readonly createdBy: IUser;
    testSteps: number[];
    configurations: number[];
    notImpactedConfigurations: number[];
    readonly id: number;
    readonly isEditable: boolean;
    readonly isDeletable: boolean;
    readonly isAssigneeEditable: boolean;
    readonly isLabelsEditable: boolean;
    readonly isStatusEditable: boolean;
    readonly isReviewEditable: boolean;
    readonly isReviewSubmittable: boolean;
    review?: string;
    reviewReason?: string;
    severity: string;
    status: string;
    summary: string;
    description: string;
    type: string;
    labels: IIssueLabel[];
    externalExportId?: string;
    externalExportLink?: string;
    readonly updatedAt?: Date | string;
    readonly updatedBy?: IUser;
    readonly subscriptionToken: string;
}

export interface IIssueMetadata {
    assignees: IMetadata[];
    configurations: IMetadata[];
    labels: IIssueLabel[];
    mentions: IUser[];
    reporters: IMetadata[];
    reviewReasons: IMetadata[];
    reviews: IMetadata[];
    scopes: IMetadata[];
    severities: IMetadata[];
    statuses: IMetadata[];
    testSteps: IMetadata[];
    types: IMetadata[];
}

export interface IChip {
    name: string;
    color: string;
    group?: string;
}

export interface IIssueLabel extends IChip {
    readonly id: number;
    project: number;
    readonly issueCount: number;
    readonly isEditable: boolean;
    readonly isDeletable: boolean;
    readonly createdBy: IUser;
}

export type IssueActivity = Activity<IIssue>;

export default IIssue;
